import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import * as api from '../../services/api'
import './Stripe.sass'
import Help from './Help'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const stripePromise = api.getPublicStripeKey().then((key) => loadStripe(key))

const App = () => {
  const [modalOpen, openModal] = useState(false)
  const onModalClose = () => {
    openModal(false)
    trackCustomEvent({
      category: 'Magazine',
      action: 'Modal.Close',
    })
  }

  const showHelp = () => {
    openModal(true)
    trackCustomEvent({
      category: 'Magazine',
      action: 'Modal.Open',
    })
  }
  return (
    <div className="box has-background-light" id="shop">
      <div className="columns">
        <div className="column">
          <h1 className="is-size-3">شراء المجلة </h1>
          <div className="content has-text-justified">
            يمكنكم الآن شراء مجلة كتابات سودانية أونلاين. الخطوات بسيطة حيث
            يمكنك أن تحدد السعر الذي ترغب في دفعه (مع وجود حد أدنى ٥ دولارات )
            وعند الدفع ستصلك رسالة الكترونية مع لينك لملف PDF يحتوي على الاصدارة
            التي قمت بشرائها.
            <div className="content has-text-centered">
              <button className="button is-centered" onClick={showHelp}>
                مزيد من المعلومات
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </div>
      </div>
      <Help modalOpen={modalOpen} onModalClose={onModalClose} />
    </div>
  )
}

export default App
