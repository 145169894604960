import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ message }) => {
  return (
    <>
      <p className="is-size-6 has-text-centered">{message}</p>
      <progress className="progress is-large is-info" max="100">
        60%
      </progress>
    </>
  )
}

Loader.propTypes = {
  message: PropTypes.string.isRequired,
}

export default Loader
