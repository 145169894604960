import React, { useEffect, useState } from 'react'
import * as api from '../../services/api'
import PaymentDetails from './PaymentDetails'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import PaymentResult from './PaymentResult'
import FreePublication from './FreePublication'
import SectionTitle from '../../components/general/SectionTitle'

export default function CheckoutForm() {
  const [products, setProducts] = useState([])
  const [clientSecret, setClientSecret] = useState(null)
  const [error, setError] = useState(null)
  const [paymentSucceeded, setPaymentSucceeded] = useState(false)
  const [processing, setProcessing] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    const getData = async () => {
      // Step 1: Fetch product details such as amount and currency from
      // API to make sure it can't be tampered with in the client.
      const { data } = await api.getProducts()
      setProducts(data)
    }

    getData()
  }, [])

  const handleConfirmPrice = async (amount, currency) => {
    const [{ s3Key: key } = {}] = products

    // Step 2: Create PaymentIntent over Stripe API
    if (amount && currency) {
      try {
        const clientSecret = await api.createPaymentIntent({
          payment_method_types: ['card'],
          amount,
          currency,
          receipt_email: 'support@ssc-sudan.org',
          metadata: {
            product: key,
          },
        })

        if (!clientSecret)
          throw new Error('Could not establish link to payment gateway')

        setClientSecret(clientSecret)
      } catch (err) {
        setError({ message: err.message })
      }
    } else {
      throw new Error('Could not establish link to payment gateway')
    }
  }

  const handleSubmitPayment = async (billingDetails) => {
    setProcessing(true)

    const [product = {}] = products
    // Step 3: Use clientSecret from PaymentIntent and the CardElement
    // to confirm payment with stripe.confirmCardPayment()
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
        metadata: {
          product: product.key,
        },
      },
    })

    if (payload.error) {
      setError({
        title: `فشلت عملية الدفع`,
        message: `يمكنك المحاولة ببطاقة أخري. سبب فشل العملية: ${payload.error.message}`,
      })
      setProcessing(false)
    } else {
      setError(null)
      setPaymentSucceeded(true)
      setProcessing(false)
    }
  }

  return (
    <>
      {products.map((product, i) => {
        if (product.minimumPrice === 0)
          return <FreePublication publication={product} />
        return (
          <div key={product.id} className="box StripeElement is-shadowless">
            <div className="columns is-multiline">
              <div
                className="column is-12 has-text-centered"
                style={{ direction: 'rtl' }}
              >
                {i === 0 && <SectionTitle highlight>عدد جديد!</SectionTitle>}
                <p
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></p>
                <hr />
              </div>
              <div className="column is-4 has-text-centered">
                <img
                  style={{ maxWidth: '300px', margin: 'auto' }}
                  src={product.image && product.image.url}
                  alt={product.name}
                />
              </div>
              <div className="column is-8">
                <div className="media-content">
                  <div className="content">
                    {paymentSucceeded && (
                      <PaymentResult
                        title="تمت عملية الدفع بنجاح"
                        message="ستصلك رسالة على عنوانك الالكتروني لتنزيل ملف المجلة. اذا حدثت أي
                    مشكلة، رجاء مراسلتنا على info@ssc-sudan.org"
                      />
                    )}
                    {!paymentSucceeded && (
                      <PaymentDetails
                        CardElement={CardElement}
                        handleConfirmPrice={handleConfirmPrice}
                        handleSubmitPayment={handleSubmitPayment}
                        product={product}
                        error={error}
                        processing={processing}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
