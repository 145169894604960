import React from 'react'
import PropTypes from 'prop-types'

const PaymentResult = ({ message, title, isError }) => {
  return (
    <article
      style={{ direction: 'rtl' }}
      className={`message ${isError ? 'is-warning' : 'is-success'}`}
    >
      <div className="message-header">
        <p className="is-size-3">{title}</p>
      </div>
      <div className="message-body has-text-centered is-size-5">{message}</div>
    </article>
  )
}

PaymentResult.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  isError: PropTypes.bool,
}

export default PaymentResult
