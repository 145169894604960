import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Loader from '../Loader'
import PaymentResult from './PaymentResult'

const PaymentDetails = ({
  product,
  handleConfirmPrice,
  handleSubmitPayment,
  error,
  processing,
  CardElement,
}) => {
  const { name } = product
  const [loadingFinished, setLoadingFinished] = useState(false)
  const [priceConfirmed, setPriceConfirmed] = useState(false)
  const [showPriceLoader, showPriceConfirmLoader] = useState(false)
  const [amountToDisplay, setAmountToDisplay] = useState(false)

  const confirmPrice = () => {
    if (formik.errors.price) return
    showPriceConfirmLoader(true)

    setTimeout(async () => {
      const { price, currency } = formik.values

      setPriceConfirmed(true)
      const priceInCents = Number(price) * 100
      await handleConfirmPrice(priceInCents, currency)
      setAmountToDisplay(getDisplayedAmount())

      showPriceConfirmLoader(false)
    }, 800)
  }

  const onStripeLoaded = () => {
    setLoadingFinished(true)
  }

  const onChangeCardDetails = (event) => {
    if (event.complete) {
      formik.setFieldValue('cardDetailsComplete', true)
    } else {
      formik.setFieldValue('cardDetailsComplete', null)
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      price: '',
      currency: 'GBP',
      cardDetailsComplete: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('الرجاء ادخال الاسم'),
      email: Yup.string()
        .email('يجب ادخال عنوان الكتروني صحيح')
        .required('يجب ادخال عنوان الكتروني'),
      cardDetailsComplete: Yup.bool().required(),
      price: Yup.number('الرجاء ادخال سعر اعلى من ٥ دولارات')
        .min(5, 'الرجاء ادخال سعر اعلى من ٥ دولارات')
        .required('الرجاء ادخال سعر اعلى من ٥ دولارات'),
    }),
    validateOnMount: true,
    onSubmit: () =>
      handleSubmitPayment({
        name: formik.values.name,
        email: formik.values.email,
      }),
  })

  const currencies = [
    { value: 'GBP', display: '£ UK', symbol: '£' },
    { value: 'USD', display: '$ US', symbol: '$' },
    { value: 'AUD', display: '$ Australia', symbol: 'AUD' },
    { value: 'EUR', display: '€ Euro', symbol: '€' },
  ]

  const getDisplayedAmount = () => {
    const { currency, price } = formik.values

    const { symbol } = currencies.find((curr) => curr.value === currency) || {}

    return `${symbol} ${price}`
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div
        className={`is-size-5 ${
          !priceConfirmed ? 'has-text-centered' : 'has-text-right'
        }`}
      >
        {name}
      </div>

      {!loadingFinished && (
        <Loader message="Preparing to collect payment information..." />
      )}

      {!priceConfirmed && (
        <>
          <label className="label has-text-right">
            اختر المبلغ الذي تود دفعه
          </label>
          <>
            <div className="field has-addons">
              <p className="control is-expanded">
                <input
                  className="input has-text-centered is-fullwidth"
                  type="number"
                  min="5"
                  max="50"
                  placeholder="المبلغ"
                  {...formik.getFieldProps('price')}
                />
              </p>
              <div className="control">
                <div className="select">
                  <select
                    {...formik.getFieldProps('currency')}
                    onChange={(ev) => {
                      formik.setFieldValue('currency', ev.target.value)
                    }}
                  >
                    {currencies.map(({ value, display }) => (
                      <option key={value} value={value}>
                        {display}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <p className="control">
              <button
                disabled={formik.errors.price}
                className="button is-fullwidth is-primary"
                onClick={confirmPrice}
              >
                Confirm / تأكيد المبلغ
              </button>
            </p>
          </>
        </>
      )}
      {showPriceLoader && (
        <progress className="progress is-small is-primary" max="100">
          15%
        </progress>
      )}
      {formik.touched.price && formik.errors.price ? (
        <div className="is-large has-text-centered has-text-danger">
          {formik.errors.price}
        </div>
      ) : null}

      <section
        className={!loadingFinished || !priceConfirmed ? 'is-hidden' : ''}
      >
        <div className="field has-text-right">
          <label className="label is-medium" style={{ direction: 'rtl' }}>
            <div className="is-inline">المبلغ المختار: &nbsp;</div>
            <div className="is-inline" style={{ direction: 'ltr' }}>
              {amountToDisplay}
            </div>
          </label>
        </div>
        <div className="field">
          <div className="control has-icons-left has-icons-right">
            <input
              className="input"
              type="text"
              placeholder="Your full name / الاسم الكامل"
              name="name"
              {...formik.getFieldProps('name')}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-user" />
            </span>
          </div>
          {formik.touched.name && formik.errors.name ? (
            <div className="help has-text-right is-danger">
              {formik.errors.name}
            </div>
          ) : null}
        </div>
        <div className="field">
          <div className="control has-icons-left has-icons-right">
            <input
              name="email"
              className="input"
              type="email"
              placeholder="me@email.com"
              {...formik.getFieldProps('email')}
            />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope" />
            </span>
          </div>
          <p className="help is-primary has-text-centered">
            * تأكد من ادخال بريد الكتروني صحيح حيث سيتم ارسال الاصدارة لهذا
            العنوان
          </p>
          {formik.touched.email && formik.errors.email ? (
            <div className="help  has-text-right is-danger">
              {formik.errors.email}
            </div>
          ) : null}
        </div>

        <div className="field">
          <CardElement
            options={stripeCardOptions}
            onChange={onChangeCardDetails}
            onReady={onStripeLoaded}
          />
        </div>

        <div className="field">
          {error && (
            <PaymentResult
              message={error.message}
              title={error.title}
              isError
            />
          )}
        </div>

        <button
          className="button is-primary is-outlined is-fullwidth"
          disabled={processing || !formik.isValid}
        >
          {processing ? 'Processing…' : 'Pay'}
        </button>
      </section>
    </form>
  )
}

const stripeCardOptions = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

PaymentDetails.propTypes = {
  product: PropTypes.object,
  handleConfirmPrice: PropTypes.func.isRequired,
  handleSubmitPayment: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  CardElement: PropTypes.func.isRequired,
  error: PropTypes.object,
}

export default PaymentDetails
