import React from 'react'
import PropTypes from 'prop-types'

const topics = [
  {
    title: 'ما نوع ملف الاصدارة؟',
    details: `<span className='content'>
    الملف هو من نوع PDF. قد تحتاج لبرنامج Adobe Acrobat لقراءته.
    العدد الثامن والخمسون متوفر مجانا ويمكنك طلبه من هذه الصفحة وستصلك رسالة الكترونية - مماثلة للرسالة التي ستصلك عند الشراء - تحتوي على المجلة لتتأكد من قدرة جهازك على قراءة الملف.
  </span>.`,
  },
  {
    title: 'هل عملية الشراء آمنة؟',
    details: `لا يقوم الموقع بحفظ بيانات البطاقة الائتمانية. تتم عملية الدفع عن طريق خدمة
    <a href="https://stripe.com" target="__blank">Stripe</a> وهي خدمة عالمية تستخدم في أكبر مواقع التسوق الاكتروني وتتولى عملية الدفع كاملة بطريقة آمنة تماما.
    `,
  },
  {
    title: 'ما سعر الاصدارة؟',
    details: `لا يهدف المركز الى جني الأرباح من الاصدارات بل تستخدم العوائد لضمان استمرارية أنشطة ومنتجات المركز المختلفة. لذا جعلنا سعر الاصدارات متغيرا، 
    أي سيكون بإمكان القارئ تحديد السعر المناسب (بدءا من ٥ دولارات كحد أدنى)
    `,
  },
  {
    title: 'هل يمكنني المساهمة بمبلغ اضافي بعد قراءة اصدارة؟',
    details: `
    اذا اقتنيت اصدارة، ورغبت في دفع مبلغ اضافي كنوع من الدعم للمركز فيمكنك ببساطة شراء نسخة اخرى عن طريق الموقع.
    `,
  },
  {
    title: 'هل يمكنني استرجاع المبلغ الذي دفعته؟',
    details: `اذا لم تكن راضيا عن الاصدارة الالكترونية - بسبب أي مشاكل تقنية - فيمكنك استرجاع المبلغ خلال يومين من عملية الشراء دون أسئلة منا. الرجاء ارسال رسالة الى 
    support@ssc-sudan.org 
     من نفس العنوان الالكتروني الذي استخدمته لشراء الاصدارة
    `,
  },
  {
    title: 'ماذا عن القراء في السودان؟',
    details: `
    نقوم حاليا بتطوير امكانية انزال الملفات مجانا لمستخدمي الموقع من السودان.
    `,
  },
  {
    title: 'كيف سيستخدم دخل المكتبة الالكترونية؟',
    details: `
      سيستخدم هذا العائد لتغطية تكاليف ومنصرفات المركز المتنوعة مثل: <br/>

    - دفع مقابل رمزي للكتاب من داخل السودان مستقبلا<br/>
    - تغطية تكاليف تصميم وتحرير مجلة كتابات سودانية<br/>
    - دعم أنشطة المركز المتنوعة <br/>
    `,
  },
  {
    title: 'هل يمكن الدفع بطرق أخرى؟',
    details: `
حاليا يمكنكم فقط الدفع عن طريق بطاقة ائتمانية. لكن هذا مشروع في بداياته، وسنقوم بمراجعته واضافة طرق أخرى إن تطلب الأمر.
    `,
  },
  {
    title: 'هل يمكن الحصول على الاصدارات بصيغة غير pdf؟',
    details: `PDF هي الصيفة الوحيدة حاليا. `,
  },
  {
    title: 'هل يمكنني نسخ ومشاركة الملف الالكتروني؟',
    details: ` لا يوجد موانع تقنية لمشاركته لكننا نتوقع الالتزام أخلاقيا بعدم تداول الملف
    سوى للاستخدام الشخصي لشاريه. 
    `,
  },

  {
    title: 'ماذا اذا واجهتني مشاكل تقنية؟',
    details: `اذا واجهتك أي مشاكل تقنية فيمكنك مراسلة فريق عمل الموقع على support@ssc-sudan.org.<br/>
     لأي استفسارات حول المحتوى وفريق التحرير، الرجاء مراسلة editor@ssc-sudan.org`,
  },
]

const Help = ({ modalOpen, onModalClose }) => {
  return (
    <div className={`modal ${modalOpen ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={onModalClose} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title has-text-centered">أسئلة معتادة</p>
        </header>
        <section className="modal-card-body">
          {topics.map(({ title, details }, i) => (
            <section key={i} style={{ direction: 'rtl' }}>
              <article className="message is-medium">
                <div className="message-header">
                  <p>{title}</p>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: details }}
                  className="message-body has-text-right"
                />
              </article>
            </section>
          ))}
        </section>
      </div>
      <button
        onClick={onModalClose}
        className="modal-close is-large"
        aria-label="close"
      />
    </div>
  )
}

Help.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
}

export default Help
