import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import StripeForm from '../components/stripe'
import EmailSubscribe from '../components/EmailSubscribe'
import ErrorBoundary from '../components/ErrorBoundary'
import PageHeader from '../components/general/PageHeader'

export const BooksPageTemplate = ({
  image,
  title,
  description,
  headingClass,
}) => {
  return (
    <div className="content">
      <PageHeader
        className={headingClass}
        backgroundImage={
          image.childImageSharp ? image.childImageSharp.fluid.src : image
        }
      >
        {title}
      </PageHeader>

      <div className="container">
        <div className="columns is-gapless is-vcentered">
          <div className="column has-text-centered has-text-weight-bold">
            {description}
          </div>
        </div>
        <StripeForm />
        <ErrorBoundary>
          <EmailSubscribe
            label="اعلمني عند صدور عدد جديد من المجلة"
            list="kitabat-latest"
            persist={false}
          />
        </ErrorBoundary>
      </div>
    </div>
  )
}

BooksPageTemplate.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  headingClass: PropTypes.string,
}

const BooksPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <BooksPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        headingClass={frontmatter.headingClass}
        description={frontmatter.description}
      />
    </Layout>
  )
}

BooksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default BooksPage

export const booksPageQuery = graphql`
  query MagazinePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        headingClass
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`
